.content_embed {
  display: flex;
  align-items: center;

  &__container {
    display: flex;
    justify-content: center;
    width: 100%;

    &_content {
      width: 100%;
      
      & > * {
        max-width: var(--embedded-max-width);
        height: var(--embedded-height);
        margin: 0 auto;
        width: 100%;
      }
    }
  }
}
