@import '../../styles/variables';
@import '../../styles/mixin';

.centered__text {
  display: flex;
  align-items: center;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
    min-height: 84px;
    width: 100%;

    @include media(sm) {
      min-height: 62px;
      padding: 0 40px !important;
    }
  }
}