@import '../../styles/variables';
@import '../../styles/mixin';

.container {
  margin-top: 15px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;

  @include media(md) {
    margin-top: 60px;
    margin-bottom: 45px;
  }

  .logo {
    display: flex;
    align-items: center;

    .logo__main {
      margin-top: 24px;
      position: relative;
      height: 55px;
      aspect-ratio: 9/4;
      max-width: 100%;

      @include media(md) {
        height: 65px;
      }
    }
  }
}
