@import '../../styles/variables';
@import '../../styles/mixin';

.figure_placeholder {
  position: relative;
  align-self: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $gray-50;
  border-radius: 8px;

  img {
    height: auto;
    border-radius: 8px;
  }

  svg {
    position: absolute;
    height: 18px;
    width: 18px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.spacing_bottom {
  margin-bottom: $margin-section-mobile;

  @include media(lg) {
    margin-bottom: $margin-section;
  }
}

.cities__section {
  display: flex;
  align-items: center;
}

.cities__carousel {
  @media screen and (max-width: 480px) {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .collections__see-all {
    font-weight: 500;
    color: $see-all-link;
    font-size: $text-sm;
    flex-shrink: 0;

    @include media(md) {
      font-size: $text-base;
    }

    span {
      margin-left: 5px;
      color: $see-all-counter !important;
    }
  }

  &_placeholder {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    width: 288px;

    @include media(lg) {
      width: 409px;
    }

    .placeholder {
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      border-radius: 4px;
      display: inline-block;
      background: $placeholder-bg;
    }

    figure {
      @extend .placeholder;
      background-color: $placeholder-bg !important;
      position: relative;
      width: 100%;
      padding-top: 56.47%;
      overflow: hidden;
      border-radius: $image-border-radius;
    }

    &_darkMode {
      figure {
        background-color: $placeholder-bg-dark !important;
      }
    }
  }
}

.slide__main {
  position: relative;
  display: flex !important;
  flex-direction: column;
  text-align: center;
  width: 288px;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.9;
  }

  @include media(sm){
    width: 365px;
  }

  @include media(lg){
    width: 409px;
  }

  &_title {
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: $text-base !important;
    text-align: left;
    color: $white;
    font-weight: 500;
    line-height: 20px;
    z-index: 3;

    @include media(sm){
      font-size: $text-xl !important;
      line-height: 24px;
    }
  }

  img {
    height: auto;
    border-radius: $border-radius-md;

  }

  &_effect {
    position: absolute;
    opacity: 1;
    background-color: transparent;
    background: linear-gradient(180deg, rgba(1, 2, 3, 0) 0%, rgba(1, 2, 3, 0.7) 100%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    &_hide {
      display: none;
    }
  }
}

.grid_main {
  margin-top: 24px;
  margin-bottom: 120px;

  @include media(md) {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(0, 1fr) auto;
  }

  .filter {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    margin-top: 20px;

    @include media(md) {
      margin-top: 25px;
    }

    .icon {
      font-size: 26px;
      color: $secondary-400;
      position: absolute;
      left: 11px;
    }

    .input {
      border-radius: 10px 0 0 10px;
      border: 2px solid $gray-200;
      padding-left: 24px;

      &:focus-within {
        border: 2px solid $primary;
      }

      .input_input__control__5IFUO {
        padding-left: 40px;
        margin-left: 0px;
      }
    }

    .button {
      border-radius: 0 10px 10px 0;
      height: 53px;
      box-shadow: none;
      font-size: 14.7px;
      letter-spacing: 0.8px;

      span {
        font-size: $text-base;
        line-height: 24px;
      }
    }
  }

  .list {
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 24px;
    row-gap: 27px;

    @include media(sm) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      row-gap: 43px;
    }

    @include media(md) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @include media(lg) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    @include media(xl) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    @include media(2xl) {
      grid-template-columns: repeat(9, minmax(0, 1fr));
      column-gap: auto;
    }

    .item {
      display: flex !important;
      flex-direction: column;
      text-align: center;

      h3 {
        order: 1;
        flex-grow: 0;
        font-size: $text-base !important;
        font-weight: 500;
        line-height: 24px;
        text-transform: uppercase;
        padding-top: 19px;
      }

      img {
        order: 0;
        flex-grow: 0;
        border-radius: $border-radius-md;
      }
    }
  }

  .load_more {
    margin-top: 45px;
    font-size: $text-lg;
    text-transform: uppercase;

    @include media(md) {
      grid-column: 1 / -1;
    }
  }
}
