@import '../../styles/variables';
@import '../../styles/mixin';

@function handle-primary($color) {
  // Check if $color is a gradient
  @if type-of($color) == 'string' and str-index($color, 'linear-gradient') {
    @return $primary;
  }

  @return $color;
}

$button-sizes: (
  sm: (
    default: (
      height: 40px,
      padding: 8px 18px,
      font-size: 14px,
    ),
    md: (
      height: 40px,
      padding: 8px 18px,
      font-size: 14px,
    ),
    sm: (
      height: 40px,
      padding: 8px 18px,
      font-size: 14px,
    ),
  ),
  md: (
    default: (
      height: 48px,
      padding: 10px 24px,
      font-size: 16px,
    ),
    md: (
      height: 48px,
      padding: 10px 24px,
      font-size: 16px,
    ),
  ),
  lg: (
    default: (
      height: 56px,
      padding: 11px 30px,
      font-size: 16px,
    ),
    md: (
      height: 48px,
      padding: 10px 24px,
      font-size: 16px,
    ),
  ),
);

@mixin button-size($size) {
  $default: map-get(map-get($button-sizes, $size), default);
  $md: map-get(map-get($button-sizes, $size), md);
  $sm: if(
    map-has-key($button-sizes, $size) and
      map-has-key(map-get($button-sizes, $size), sm),
    map-get(map-get($button-sizes, $size), sm),
    $md
  );

  height: map-get($default, height);
  padding: map-get($default, padding);
  font-size: map-get($default, font-size);

  @media (max-width: 1280px) {
    height: map-get($md, height);
    padding: map-get($md, padding);
    font-size: map-get($md, font-size);
  }

  @if $size == sm {
    @media (max-width: 520px) {
      height: map-get($sm, height);
      padding: map-get($sm, padding);
      font-size: map-get($sm, font-size);
    }
  }
}

.button {
  border-radius: $border-radius-btn;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;

  .button__content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border: 0 !important;
    background-color: transparent !important;

    .spinner {
      margin-right: 7px;
    }
  }

  &.button_sm {
    @include button-size(sm);
  }

  &.button_md {
    @include button-size(md);
  }

  &.button_lg {
    @include button-size(lg);
  }

  &.button_color-primary {
    color: $label-btn-primary;
    background: $btn-primary;
    text-transform: uppercase;
    border: $border-width-btn solid $border-btn-primary;

    svg {
      * {
        stroke: $label-btn-primary;
      }
    }

    &:not(:disabled):hover {
      color: $label-btn-primary-hover;
      background: $btn-primary-hover;
      border: $border-width-btn solid $border-btn-primary-hover;

      svg {
        * {
          stroke: $label-btn-primary-hover;
        }
      }
    }

    &:not(:disabled):focus {
      color: $label-btn-primary-pressed;
      background: $btn-primary-pressed;
      border: $border-width-btn solid $border-btn-primary-pressed;

      svg {
        * {
          stroke: $label-btn-primary-pressed;
        }
      }
    }

    &:disabled {
      color: $label-btn-primary-disabled;
      background: $btn-primary-disabled;
      border: $border-width-btn solid $border-btn-primary-disabled;

      svg {
        * {
          stroke: $label-btn-primary-disabled;
        }
      }
    }
  }

  &.button_color-secondary {
    color: $label-btn-secondary;
    border: $border-width-btn solid $btn-secondary;
    background: $background-btn-secondary;
    text-transform: uppercase;
    width: 100%;

    svg {
      * {
        stroke: $label-btn-secondary;
      }
    }

    &:not(:disabled):hover {
      border: $border-width-btn solid $btn-secondary-hover;
      color: $label-btn-secondary-hover;
      background: $background-btn-secondary-hover;

      svg {
        * {
          stroke: $label-btn-secondary-hover;
        }
      }
    }

    &:not(:disabled):focus {
      background: $background-btn-secondary-pressed;
      color: $label-btn-secondary-pressed;
      border: $border-width-btn solid $btn-secondary-pressed;

      svg {
        * {
          stroke: $label-btn-secondary-pressed;
        }
      }
    }

    &:disabled {
      background: $btn-secondary-disabled;
      color: $label-btn-secondary-disabled;
      border: $border-width-btn solid $background-btn-secondary-disabled;

      svg {
        * {
          stroke: $label-btn-secondary-disabled;
        }
      }
    }
  }

  &.button_color-tertiary {
    $processed-color: handle-primary($btn-primary);
    $processed-color-hover: handle-primary($btn-primary-hover);
    color: get-value($label-btn-primary, $label-btn-tertiary);
    background: get-value(lighten($processed-color, 24%), $btn-tertiary);
    text-transform: uppercase;

    svg {
      * {
        stroke: get-value($label-btn-primary, $label-btn-tertiary);
      }
    }

    &:not(:disabled):hover {
      color: get-value($label-btn-primary-hover, $label-btn-tertiary-hover);
      background: get-value(
        lighten($processed-color-hover, 18%),
        $btn-tertiary-hover
      );

      svg {
        * {
          stroke: get-value(
            $label-btn-primary-hover,
            $label-btn-tertiary-hover
          );
        }
      }
    }

    &:not(:disabled):focus {
      color: get-value($label-btn-primary-hover, $label-btn-tertiary-pressed);
      background: get-value(
        lighten($processed-color-hover, 18%),
        $btn-tertiary-pressed
      );

      svg {
        * {
          stroke: get-value(
            $label-btn-primary-hover,
            $label-btn-tertiary-pressed
          );
        }
      }
    }

    &:disabled {
      color: get-value(
        $label-btn-primary-disabled,
        $label-btn-tertiary-disabled
      );
      background: get-value($btn-primary-disabled, $btn-tertiary-disabled);

      svg {
        * {
          stroke: get-value(
            $label-btn-primary-disabled,
            $label-btn-tertiary-disabled
          );
        }
      }
    }
  }

  &.button_color-link {
    color: $label-btn-link;

    svg {
      * {
        stroke: $label-btn-link;
      }
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      color: $label-btn-link-hove;

      svg {
        * {
          stroke: $label-btn-link-hove;
        }
      }
    }
  }

  &.button_icon {
    width: 47px;
    height: 47px;
    padding: 0;
    border: 0;
  }

  &.footer {
    background: $btn-background-footer;
    border-radius: $border-radius-btn;
    width: 100%;
    padding: 11px 16px;
    text-align: center;

    .button__content {
      font-weight: 500;
      font-size: $text-sm;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: $text-btn-footer;

      i {
        color: $btn-background-footer;
      }

      .spinner {
        margin-right: 13px;
      }
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background: $btn-background-footer-hover;

      .button__content {
        color: $text-btn-footer-hover;
      }
    }
  }

  .icon {
    font-size: $text-xl;
  }

  &:not(.button_icon) .icon {
    margin-right: 4px;
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
