@import '../../styles/variables';
@import '../../styles/mixin';

.age {
  font-size: $text-base;
  position: fixed;

  div[class~='modal__content'] {
    background-color: $age-background-color;
    border: $age-border-card;
    backdrop-filter: $age-backdrop;
    box-shadow: $age-modal-shadow;
    max-width: $age-card-width !important;
  }

  div[class~='modal__body'] {
    background-color: transparent;
    letter-spacing: 0.2px;
    text-align: center;
    line-height: 1.5;
  }

  .age__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem;

    img {
      width: 100%;
      max-width: $age-logo-max-width;
      height: $age-logo-max-height;
      margin: auto;
    }
  }

  .age__heading {
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.15rem;
    text-align: center;
    color: $age-title;
    margin: 0.5rem 0;
  }

  .age__message {
    font-size: $text-sm;
    margin-top: 1rem;
    text-align: center;

    p {
      margin-bottom: 25px;
      color: $age-message;

      a {
        font-weight: 700;
        transition: color ease 0.2s;
        color: $age-message;
      }

      a:hover {
        color: $age-message-hover;
      }
    }
  }

  .option {
    height: 30px !important;

    label {
      position: relative;
      top: 1.5px;
    }
  }

  .separator {
    margin: 10px 0;
  }

  .link {
    color: $white;
    font-weight: 600;

    &:not(:last-child) {
      font-weight: 700;
      transition: $transition-all;

      &:hover {
        color: $gray-400;
      }
    }

    &:last-child {
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .age__options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    font-size: $text-sm;
    color: $age-link;
    font-weight: 500;

    .age__error {
      content: '';
      margin-bottom: 4px;
      font-size: $text-sm;
      text-align: left;
      color: $errors;
      position: relative;
      width: 100%;
    }

    button {
      border-radius: $age-btn-primary-border-radius !important;
      font-size: $text-sm;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      max-width: $age-button-max-width;
      width: 100%;
    }

    a {
      text-transform: uppercase;
      margin-top: 16px;
    }
  }
}
