@import '../../styles/variables';
@import '../../styles/mixin';

.account__menu__user {
  background: $white;
  border-radius: $border-radius;
  border: 0.5px solid $gray-200;
  box-shadow: $box-shadow-2;
  display: flex;
  flex-direction: column;
  width: 234px;
  padding: 24px;
  position: absolute;
  transform: translateY(10px) translateX(-152px);
  user-select: none;
  font-weight: 500;
  line-height: 26px;
  color: $gray-600;
  z-index: 3;
  font-size: $text-lg;

  @include media(sm) {
    transform: translateY(10px) translateX(-127px);
  }
  @include media(lg) {
    transform: translateY(46px) translateX(0px);
  }
  p {
    align-items: center;
    color: $secondary-300;
    display: flex;
    width: 186px;

    span {
      display: inline-block;
      max-width: 181px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .links {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;
    font-weight: 400;
    padding-bottom: 16px;
    border-bottom: 0.5px solid $gray-200;
  }
  .signout {
    padding-top: 16px;
    font-weight: 700;
    width: max-content;
    font-size: $text-lg;
  }
  .peak {
    background: $white;
    border-top: 0.5px solid $gray-200;
    border-left: 0.5px solid $gray-200;
    box-shadow: -10px 0px 12px rgba(186, 186, 186, 0.3);
    height: 12px;
    position: absolute;
    width: 12px;
    top: -7px;
    right: 9px;
    transform: rotateZ(45deg);
  }
}
