@use "sass:math";
@import '../../styles/variables';
@import '../../styles/mixin';

.accordion {
  display: flex;
  flex-direction: column;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  border-bottom: 0.5px solid $filter-algolia-divider;
  padding: 16px 0;

  &:last-child {
    border-bottom: none;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.prepended_icon {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    &_title {
      font-weight: 500;
      font-size: $text-base;
      font-family: var(--font-family);
      color: $filter-algolia-title;
      padding-block: 5px;
    }

    &_icon {
      color: $filter-icon-color;
      font-size: $text-2xl;
    }
  }

  &_body {
    overflow: hidden;
    max-height: 10000px;
    transition: max-height 1s ease-in-out;
  }

  &_body[aria-expanded='true'] {
    max-height: 0px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
}
