@import '../../../styles/variables';
@import '../../../styles/mixin';

.price {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  text-align: right;
  color: $gray-600;

  del {
    font-weight: 400;
    font-size: $text-sm;
    line-height: 18px;
    text-decoration-line: line-through;
    color: $discount-price;
  }

  ins {
    font-weight: 700;
    line-height: 28px;
    font-size: $text-2xl;
    text-decoration: none;
  }

  .tax_message {
    line-height: 11px;
    text-transform: lowercase !important;
    font-size: 11px;
  }
}
