.image {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  filter: none;
  background-size: cover;
  background-image: none;

  &.contain {
    object-fit: contain;
  }

  &.cover {
    object-fit: cover;
  }

  &.fill {
    object-fit: fill;
  }

  &.right {
    object-position: right;
  }

  &.bottom {
    object-position: bottom;
  }
}
