@import '../../styles/variables';
@import '../../styles/mixin';

.container {
  color: $white;
  display: flex;
  justify-content: center;
  padding: 20px 0;

  span {
    border-radius: $border-radius-btn;
    cursor: pointer;
    font-size: $text-sm;
    font-weight: 500;
    padding: 11px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: $transition-all;
    overflow: hidden;
  }
}
