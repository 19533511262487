@import '../../styles/variables';
@import '../../styles/mixin';

.layout_column {
  display: flex;
  flex-direction: column;

  gap: 13px;

  @include media(sm){
    gap: 16px;
  }

};

.article {
  @extend .layout_column;
  transition: all 0.3s ease;

  &:hover, &:focus-within {
    figure {
      opacity: .8;
    }
  }

  .article__figure {
    position: relative;
    width: 100%;
    padding-top: 73%;
    overflow: hidden;
    border-radius: $border-radius-md;
    background-color: $gray-50;
    margin-bottom: 13px;

    img {
      height: auto;
    }

    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 22px;
      color: $gray-300;
    }
  }

  .article__body{
    @extend .layout_column;
  }

  .article__info {
    order:2;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  time,
  p {
    font-size: $text-sm;
    font-weight: light;
    color: $gray-300;
    //text-transform: uppercase;
  }

  .article__sharing {
    line-height: 16px;
    padding: 6.5px 10.5px;

    i {
      font-size: $text-base;
    }

    button:not(:last-child) {
      padding-right: 13.5px;
    }

    button:not(:first-child) {
      padding-left: 13.5px;
    }
  }

  .article__title {
    order: 3;
    font-style: normal;
    font-weight: 700;
    font-size: $text-lg !important;
    line-height: 22px;

    @include media(sm) {
      font-size: $text-xl !important;
      line-height: 24px;
    }
  }

  .article__description {
    order:4;
    font-style: normal;
    font-weight: 400;
    font-size: $text-sm;
    line-height: 20px;
    color: $gray-500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;

    @include media(sm) {
      font-size: $text-base;
      line-height: 24px;
    }
  }
}
