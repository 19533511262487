@import '../../styles/variables';
@import '../../styles/mixin';

.articles__collection {
  display: grid;
  column-gap: 24px;
  height: max-content;
  row-gap: 16px;
  margin: 32px 0 64px;

  @media screen and (min-width: 460px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  } 

  @include media(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include media(2xl) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @include media(3xl) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  &_post_article {
    width: 100%;

    div[class~='article_article__body__SUIxL'] {
      padding: 12px;
      gap: 0;

      @include media(sm){
        padding: 16px;
      }
    }

    h5 {
      font-weight: 500 !important;
      color: $related-post-title-color;
      order: 1 !important;
    }

    div[class~='article_article__info__jMXmR'] {
      order: 2;
      margin: 8px 0 0;

      > div {
        display: none;

        @include media(lg){
          display: flex;
          flex-wrap: nowrap;
          border: 0 !important;
          align-self: flex-end;
          padding-block: 0 !important;
          color: $related-post-title-color;
        }     
      }
    }

    time {
      color: $related-post-time-color;
    }

    p[class~='article_article__description__rfvQX']{
      display: none;
    }

    figure {
      width: 100;
      padding-top: 58% !important;
      margin-bottom: 0 !important;
      border-radius: $image-border-radius;
      overflow: hidden;

      img {
        height: auto;
      }
    }
  }
}

.articles__load-more {
  display: flex;
  align-items: center;
  margin: 64px auto 40px;
  text-transform: uppercase;
  font-size: $text-lg !important;
}

.article__placeholder {
  width: 100%;

  .placeholder {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    border-radius: 4px;
    display: inline-block;
    background: $placeholder-bg;
  }

  figure {
    @extend .placeholder;
    background-color: $product-placeholder-image-color !important;
    position: relative;
    width: 100%;
    padding-top: 73%;
    overflow: hidden;
    border-radius: $image-border-radius;
    margin-bottom: 6px;
  }

  h5,
  span {
    @extend .placeholder;
  }

  .article__body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .article__name {
     width: 100%;
     height: 20px;
  }

  .article__info {
    order:2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .article__time {
    width: 40%;
    height: 16px;
  }

  .article__sharing {
    width: 80px;
    display: flex;
    gap: 12px;
  }

  .article__sharing_social {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: $placeholder-bg-200
  }
}
