@import '../../styles/variables';
@import '../../styles/mixin';

.blog_article__related_post {
  padding-top: 24px;
  padding-bottom: 28px;
  margin-bottom: $related-post-marging-bottom;
  background-color: $related-post-background;

  @include media(sm){
    padding-top: 28px;
    padding-bottom: 26px;
  }

  @media screen and (max-width: 479px) {
    > div {
      margin-left: 15px;
    }

    div[class~='Slider_slider_header__bpIxH'] {
      padding: 0;
    }
  }

  &_article {
    width: 288px;

    @include media(sm){
      width: 365px;
    }

    @include media(lg){
      width: 409px;
    }

    div[class~='article_article__body__SUIxL'] {
      padding: 12px;
      gap: 0;

      @include media(sm){
        padding: 16px;
      }
    }

    h5 {
      font-weight: 500 !important;
      color: $related-post-title-color;
      order: 1 !important;
    }

    div[class~='article_article__info__jMXmR'] {
      order: 2;
      margin: 8px 0 0;

      > div {
        display: none;

        @include media(lg){
          display: flex;
          flex-wrap: nowrap;
          border: 0 !important;
          align-self: flex-end;
          padding-block: 0 !important;
          color: $related-post-title-color;
        }     
      }
    }

    time {
      color: $related-post-time-color;
    }

    p[class~='article_article__description__rfvQX']{
      display: none;
    }

    figure {
      width: 100;
      padding-top: 58% !important;
      margin-bottom: 0 !important;
      border-radius: $image-border-radius;
      overflow: hidden;

      img {
        height: auto;
      }
    }
  }
  
 
}
