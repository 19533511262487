@import '../../styles/variables';
@import '../../styles/mixin';

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
