@import '../../styles/variables';
@import '../../styles/mixin';

.address-picker {
  .address_actions_icons {
    margin-left: 30px;
  }

  .divider {
    height: 1px;
    background-color: $gray-200;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .heading_level-3 {
    font-weight: 500;
    font-size: $text-base !important;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: $gray-600;
    margin-bottom: 32px;
  }

  address {
    font-style: normal;
    margin-bottom: 20px;

    .address_select-address-container {
      display: flex;
      justify-content: space-between;

      + .address_select-address-container {
        margin-top: 16px;
      }

      .address_item {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        color: $label-btn-mode-secondary;
        height: 100%;
        font-weight: 500;
        cursor: pointer;
        z-index: 2;

        .address_label {
          display: flex;
          flex-direction: column;
        }

        .input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: $white;
          border: 2px solid $input-ckek-border-color;
          border-radius: 50%;
          height: 16px;
          margin: 0;
          outline: 0;
          position: relative;
          width: 16px;
          min-width: 16px;

          &::before {
            content: '';
            width: 6px;
            height: 6px;
            display: none;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            position: absolute;
            cursor: pointer;
          }

          &:checked {
            border-color: $input-check-color;
            &::before {
              background-color: $input-check-color;
              display: inline-block;
            }
          }
        }
      }
    }

    .btn_remove {
      i {
        color: $account-btn-link !important;

        &:hover {
          color: $account-btn-link-hover !important;
        }
      }
    }

    .input {
      width: 100%;
      flex: 1 1 0;
      @at-root {
        .address-picker_editable address .input {
          width: calc(100% - 38px);
        }
      }

      span,
      small {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }

      .address1 {
        font-weight: 400;
        font-size: $text-base;
        line-height: 24px;
        color: $gray-600;
        margin-bottom: 2px;
        max-width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media(lg) {
          display: flex;
          align-items: center;
          max-width: max-content;
        }
      }
      .city {
        font-weight: 400;
        font-size: $text-xs;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: $gray-400;
      }
    }

    button {
      font-size: 22px !important;
      height: fit-content !important;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.2;

        i {
          cursor: not-allowed;
        }
      }
    }
  }

  .address-picker__current {
    margin-top: 8px;
    padding-left: 0;
  }

  .address_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  div[class='modal__content'] {
    padding-bottom: 32px;
  }

  .input__apartment {
    margin-top: 12px;
  }

  .address__actions {
    display: flex;
    gap: 16px;
  }

  .address__btn {
    text-transform: uppercase;
    margin-top: 32px;
    width: 50% !important;
  }
}
