@import '../../styles/variables';
@import '../../styles/mixin';

.account__photo {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;

  .account__photo_title {
    order: 2;
    text-align: center;
    font-size: $text-sm;
    color: $gray-500;
  }

  .account__photo_container {
    position: relative;
    order: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .spinner_photo {
    color: $label-btn-link;
    font-size: $text-sm;
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .account__documment_img, .account__document_input_upload  {
    position: relative;
    border-radius: $border-radius-md;
    height: 0;
    padding-top: 63.0%;
    width: 100%;
  }

  .account__documment_img {
    position: absolute;
    overflow: hidden;
    
    &_perfil {
      border-radius: $border-radius-full;
      height: 176px;
      padding-top: 0;
      width: 176px;
    }
  }

  .account__document_input_upload {
    display: block;
    position: relative;
    border: 3px dashed $gray-200;
    text-align: center;
    cursor: pointer;
    color: $gray-200;
    border-radius: $border-radius-md;
    transition: border-color 0.3s ease-in-out;
    font-size: $text-sm;

    &:hover, &:focus {
      border: 3px dashed $label-btn-link-hove;

      strong {
        opacity: 0.8;
        color: $label-btn-link-hove;
        
        svg {
          *{
            stroke: $label-btn-link-hove;
          }
        }

      }
    }

    .account__photo-input_loading {
      cursor: not-allowed;
    }

    &_message {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    strong {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      text-align: center;
      justify-content: center;
      row-gap: 4px;
      width:200px;
      color: $gray-200;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

      svg {
        display: block;
        width: 36px;
        height: 36px;

        *{
          stroke: $gray-200;
        }
      }

      span {
        font-weight: 400 !important;
      }
    }

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    &_perfil {
      border-radius: $border-radius-full;
      height: 176px;
      padding-top: 0;
      width: 176px;

      strong {
        row-gap: 4px;
        width:160px;
        font-size: $text-xs;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &_borderless {
      border: 0;

      &:hover,
      &:focus {
        border: 0;
      }
    }

    .account__edit_action {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary;
      border: 2px solid $primary;
      color: $white;
      right: -8px;
      bottom: -8px;
      border-radius: $border-radius-full;
      border: none;
      width: 32px;
      height: 32px;
      z-index: 1;

      &_perfil {
        top: 144px;
        right: 20px;
      }

      i {
        margin: 0 auto;
        color: $white;
      }
    }
  }
}
